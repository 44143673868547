var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("v-card-title", [_vm._v("Organizations")]),
              _c("v-text-field", {
                attrs: {
                  label: "Search by " + _vm.table.searchField,
                  outlined: "",
                  type: "text",
                  "append-icon": "mdi-magnify",
                  disabled: _vm.isLoading,
                  loading: _vm.isLoading
                },
                on: {
                  "click:append": _vm.searchTable,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchTable.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.searchInput,
                  callback: function($$v) {
                    _vm.searchInput = $$v
                  },
                  expression: "searchInput"
                }
              }),
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          "Filters " + _vm._s(_vm.queryParamFormattedFilter)
                        )
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-container",
                            [
                              _vm._l(_vm.table.filter, function(
                                filterItem,
                                filterIndex
                              ) {
                                return _c(
                                  "v-row",
                                  { key: filterIndex },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-combobox", {
                                          attrs: {
                                            label: "Key",
                                            "single-line": "",
                                            "hide-details": "",
                                            items: _vm.table.filtersAutocomplete
                                          },
                                          on: {
                                            keydown: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.searchTable.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          },
                                          model: {
                                            value: filterItem.key,
                                            callback: function($$v) {
                                              _vm.$set(filterItem, "key", $$v)
                                            },
                                            expression: "filterItem.key"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Value",
                                            "single-line": "",
                                            "hide-details": ""
                                          },
                                          on: {
                                            keydown: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.searchTable.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          },
                                          model: {
                                            value: filterItem.value,
                                            callback: function($$v) {
                                              _vm.$set(filterItem, "value", $$v)
                                            },
                                            expression: "filterItem.value"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "col-1" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mt-6",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.table.filter.splice(
                                                  filterIndex,
                                                  1
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [_vm._v("mdi-delete")])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.addFilter()
                                            }
                                          }
                                        },
                                        [_vm._v("Add Filter")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { block: "", color: "primary" },
                  on: { click: _vm.searchTable }
                },
                [_vm._v("Search")]
              ),
              _c("v-data-table", {
                attrs: {
                  loading: _vm.table.loading,
                  items: _vm.table.data,
                  headers: _vm.table.headers,
                  "items-per-page": _vm.table.itemsPerPage,
                  "hide-default-footer": "",
                  "item-key": "uuid",
                  "server-items-length": 1,
                  options: _vm.options
                },
                on: {
                  "update:options": function($event) {
                    _vm.options = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.organizationId",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", dark: "", icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.showDialog(item)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-eye")])],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "v-container",
                { staticClass: "text-center pt-2" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-pagination", {
                            attrs: {
                              length: _vm.table.pageCount,
                              "total-visible": 7
                            },
                            on: {
                              input: function($event) {
                                return _vm.getNextPage()
                              }
                            },
                            model: {
                              value: _vm.table.page,
                              callback: function($$v) {
                                _vm.$set(_vm.table, "page", $$v)
                              },
                              expression: "table.page"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              items: [
                                { text: 25, value: 25 },
                                { text: 50, value: 50 },
                                { text: 100, value: 100 },
                                { text: "All", value: -1 }
                              ],
                              "item-text": "text",
                              "item-value": "value",
                              label: "Rows per page"
                            },
                            model: {
                              value: _vm.itemsPerPageInput,
                              callback: function($$v) {
                                _vm.itemsPerPageInput = $$v
                              },
                              expression: "itemsPerPageInput"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "retain-focus": false, scrollable: "" },
          model: {
            value: _vm.isDialogShown,
            callback: function($$v) {
              _vm.isDialogShown = $$v
            },
            expression: "isDialogShown"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", { staticClass: "text-h5 mr-4" }, [
                    _vm._v(
                      _vm._s(_vm.dialogItem.domainName) +
                        " (" +
                        _vm._s(_vm.dialogItem.domainId) +
                        ")"
                    )
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.isDialogShown = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: "Organization ID", readonly: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", dark: "", icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.clipyboardCopyWithNotification(
                                      _vm.dialogItem.organizationId || ""
                                    )
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-content-copy")])],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.dialogItem.organizationId,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogItem, "organizationId", $$v)
                      },
                      expression: "dialogItem.organizationId"
                    }
                  }),
                  _c("pre", [
                    _vm._v(
                      _vm._s(_vm.jsonPretty(_vm.dialogItem)) +
                        "\n                "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.isDialogShown = false
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }